import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from 'gatsby'
import media from "../../../styles/breakPoints"
import { useIntl } from 'gatsby-plugin-intl';

import Layout from "../../../components/Layout";
import SEO from "../../../components/seo"

import Section from "../../../objects/Section"
import ProductSection from "../../../components/ProductSection"

// import { products } from "./content"

const WrapSections = styled(Section)`
   background-color: ${props => props.theme.color.grayLight};
   flex-direction: column;
   padding: 0;
   align-items: flex-start;

   ${media.greaterThan("md")`
      padding: 20px 0;
   `}

   ${media.greaterThan("lg")`
      padding: 40px 0;
   `}

`

const ProdutosDiversos = () => {

   const intl = useIntl();

   const content = useStaticQuery(
      graphql`
         query {
            imgPD_Row1: allFile (
               filter: 
                  {  relativeDirectory: { eq: "produtos-diversos/row1" }},
                     sort: {order: ASC, fields: childImageSharp___fixed___originalName}) {
               edges {
                  node {
                     childImageSharp {
                        fluid(maxHeight: 180) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
            imgPD_Row2: allFile (
               filter: 
                  {  relativeDirectory: { eq: "produtos-diversos/row2" }},
                     sort: {order: ASC, fields: childImageSharp___fixed___originalName}) {
               edges {
                  node {
                     childImageSharp {
                        fluid(maxHeight: 180) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
            imgPD_Row3: allFile (
               filter: 
                  {  relativeDirectory: { eq: "produtos-diversos/row3" }},
                     sort: {order: ASC, fields: childImageSharp___fixed___originalName}) {
               edges {
                  node {
                     childImageSharp {
                        fluid(maxHeight: 180) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
            imgPD_Row4: allFile (
               filter: 
                  {  relativeDirectory: { eq: "produtos-diversos/row4" }},
                     sort: {order: ASC, fields: childImageSharp___fixed___originalName}) {
               edges {
                  node {
                     childImageSharp {
                        fluid(maxHeight: 180) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
            imgPD_Row5: allFile (
               filter: 
                  {  relativeDirectory: { eq: "produtos-diversos/row5" }},
                     sort: {order: ASC, fields: childImageSharp___fixed___originalName}) {
               edges {
                  node {
                     childImageSharp {
                        fluid(maxHeight: 180) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
            imgPD_Row6: allFile (
               filter: 
                  {  relativeDirectory: { eq: "produtos-diversos/row6" }},
                     sort: {order: ASC, fields: childImageSharp___fixed___originalName}) {
               edges {
                  node {
                     childImageSharp {
                        fluid(maxHeight: 180) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
            imgPD_Row7: allFile (
               filter: 
                  {  relativeDirectory: { eq: "produtos-diversos/row7" }},
                     sort: {order: ASC, fields: childImageSharp___fixed___originalName}) {
               edges {
                  node {
                     childImageSharp {
                        fluid(maxHeight: 180) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
            imgPD_Row8: allFile (
               filter: 
                  {  relativeDirectory: { eq: "produtos-diversos/row8" }},
                     sort: {order: ASC, fields: childImageSharp___fixed___originalName}) {
               edges {
                  node {
                     childImageSharp {
                        fluid(maxHeight: 180) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
            pdfPD_Row1: allFile (
               filter: 
                  {  relativeDirectory: { eq: "produtos-diversos-row1" }},
                     sort: {order: ASC, fields: name} ) {
               edges {
                  node {
                     publicURL
                  }
               }
               totalCount
            }
            pdfPD_Row2: allFile (
               filter: 
                  {  relativeDirectory: { eq: "produtos-diversos-row2" }},
                     sort: {order: ASC, fields: name} ) {
               edges {
                  node {
                     publicURL
                  }
               }
               totalCount
            }
            pdfPD_Row3: allFile (
               filter: 
                  {  relativeDirectory: { eq: "produtos-diversos-row3" }},
                     sort: {order: ASC, fields: name} ) {
               edges {
                  node {
                     publicURL
                  }
               }
               totalCount
            }
            pdfPD_Row4: allFile (
               filter: 
                  {  relativeDirectory: { eq: "produtos-diversos-row4" }},
                     sort: {order: ASC, fields: name} ) {
               edges {
                  node {
                     publicURL
                  }
               }
               totalCount
            }
            pdfPD_Row6: allFile (
               filter: 
                  {  relativeDirectory: { eq: "produtos-diversos-row6" }},
                     sort: {order: ASC, fields: name} ) {
               edges {
                  node {
                     publicURL
                  }
               }
               totalCount
            }
            pdfPD_Row7: allFile (
               filter: 
                  {  relativeDirectory: { eq: "produtos-diversos-row7" }},
                     sort: {order: ASC, fields: name} ) {
               edges {
                  node {
                     publicURL
                  }
               }
               totalCount
            }
            pdfPD_Row8: allFile (
               filter: 
                  {  relativeDirectory: { eq: "produtos-diversos-row8" }},
                     sort: {order: ASC, fields: name} ) {
               edges {
                  node {
                     publicURL
                  }
               }
               totalCount
            }
         }      
      `
   )
  
   return (
      <Layout>
         <SEO 
            lang={intl.locale}
            title={intl.formatMessage({ id: "navdropdown.item4" })}
            keywords={[ `indústria metalúrgica em São Paulo`, `contato elétrico`, `bobina magnética`, `cordoalha`, `bobina de sopro`, `abafador de arco`, `contato auxiliar`, `pantógrafo`, `sapata coletora`, `carro porta-cabos`, `sistema Festoon`, `cortina de cabos`, `isolador`, `sob encomenda` ]}
         />
         <WrapSections>
            <ProductSection
               tagAs="h1"
               productline="produtosdiversospage"
               row="contentPD_Row1"
               section={intl.formatMessage({ id: "produtosdiversospage.contentPD_Row1.section" })}
               element="glide-produtos-diversos-row1" 
               children={content.imgPD_Row1.edges}
               catalog={content.pdfPD_Row1.edges}
               totalCount={content.pdfPD_Row1.totalCount}
            />
            <ProductSection
               productline="produtosdiversospage"
               row="contentPD_Row2"
               section={intl.formatMessage({ id: "produtosdiversospage.contentPD_Row2.section" })}
               element="glide-produtos-diversos-row2" 
               children={content.imgPD_Row2.edges}
               catalog={content.pdfPD_Row2.edges}
               totalCount={content.pdfPD_Row2.totalCount}
            />
            <ProductSection
               productline="produtosdiversospage"
               row="contentPD_Row3"
               section={intl.formatMessage({ id: "produtosdiversospage.contentPD_Row3.section" })}
               element="glide-produtos-diversos-row3" 
               children={content.imgPD_Row3.edges}
               catalog={content.pdfPD_Row3.edges}
               totalCount={content.pdfPD_Row3.totalCount}
            />
            <ProductSection
               productline="produtosdiversospage"
               row="contentPD_Row4"
               section={intl.formatMessage({ id: "produtosdiversospage.contentPD_Row4.section" })}
               element="glide-produtos-diversos-row4" 
               children={content.imgPD_Row4.edges}
               catalog={content.pdfPD_Row4.edges}
               totalCount={content.pdfPD_Row4.totalCount}
            />
            <ProductSection
               productline="produtosdiversospage"
               row="contentPD_Row5"
               section={intl.formatMessage({ id: "produtosdiversospage.contentPD_Row5.section" })}
               element="glide-produtos-diversos-row5" 
               children={content.imgPD_Row5.edges}
               // catalog={}
               totalCount={0}
            />
            <ProductSection
               productline="produtosdiversospage"
               row="contentPD_Row6"
               section={intl.formatMessage({ id: "produtosdiversospage.contentPD_Row6.section" })}
               element="glide-produtos-diversos-row6" 
               children={content.imgPD_Row6.edges}
               catalog={content.pdfPD_Row6.edges}
               totalCount={content.pdfPD_Row6.totalCount}
            />
            <ProductSection
               productline="produtosdiversospage"
               row="contentPD_Row7"
               section={intl.formatMessage({ id: "produtosdiversospage.contentPD_Row7.section" })}
               element="glide-produtos-diversos-row7" 
               children={content.imgPD_Row7.edges}
               catalog={content.pdfPD_Row7.edges}
               totalCount={content.pdfPD_Row7.totalCount}
            />
            <ProductSection
               productline="produtosdiversospage"
               row="contentPD_Row8"
               section={intl.formatMessage({ id: "produtosdiversospage.contentPD_Row8.section" })}
               element="glide-produtos-diversos-row8" 
               children={content.imgPD_Row8.edges}
               catalog={content.pdfPD_Row8.edges}
               totalCount={content.pdfPD_Row8.totalCount}
            />
         </WrapSections>
      </Layout>
   )
}

export default ProdutosDiversos